import { Component } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  showAuthorizeMenu: boolean;
  isAdmin: boolean;

  constructor(private dataService: DataService) {
    this.showAuthorizeMenu = false;
  }


  ngOnInit() {
    this.dataService.TokenValidity().subscribe(result => this.TokenResult(result));
  }


  public TokenResult(result: any) {
    if (result == 1)
      this.showAuthorizeMenu = true;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
