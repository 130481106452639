import { Injectable, OnInit } from '@angular/core';
import { retry, catchError, subscribeOn } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelper } from './jwtHelper';
import {
  HttpClient, HttpHeaders, HttpResponse, HttpRequest,
  HttpHandler,
  HttpEvent, HttpParams,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

//Good reference - https://www.positronx.io/angular-8-httpclient-http-tutorial-build-consume-restful-api/

@Injectable({
  providedIn: 'root',
})
export class DataService {

  public token = null;
  private tokenValid; // <0 is invalid >0 is valid
  private tokenValiditySubject: BehaviorSubject<any>;
  public currentUserEmailAddress: string;
  public _userAccountsRequests: UserAccountRequest[];
  public microsoftToken = null;
  public microsoftCode = null;

  public microsoftClientId = "29ff014f-789e-4eef-a1ad-bf35b734ebd9";
  private microsoftTenantId = "2914ee4b-b6b3-4280-b3c4-158e4e23cf2d";
  private _baseUrl = "https://irslauthenticationservicedev.irsltesting.com";
  private _portalUrl;
  public _domain;
  constructor(private router: Router, private http: HttpClient) {

    this.tokenValiditySubject = new BehaviorSubject<any>(0);
    
  }

  public initialize() {
    return this.http.get<any>("/service/url").toPromise().then(result => {
      this._baseUrl = result.service;
      this._portalUrl = result.portal;
      this._domain = result.domain;
    });
  }

  public SetToken(token: string) {

    if (token == null) {
      this.tokenValid = -1;
      this.tokenValiditySubject.next(-1);
    }
    else {
      this.token = token;
      this.TokenCheck().subscribe(result => this.TokenResult(result), error => this.HandleError(error));
    }
  }

  public TokenValidity(): Observable<any> {

    return this.tokenValiditySubject.asObservable();

  }

  private HandleError(error: any) {
    this.tokenValid = -1;
    this.tokenValiditySubject.next(-1);
  }

  private TokenResult(result: any) {

    let jwtHelper = new JwtHelper();
    let decodedToken = jwtHelper.decodeToken(this.token);

    if (decodedToken.Permission == "Add User") {
      this.tokenValid = 1;
      this.tokenValiditySubject.next(1);



      this.currentUserEmailAddress = decodedToken.sub;

    }
    else {
      this.tokenValid = -1;
      this.tokenValiditySubject.next(-1);
    }
  }


  public SendPing() {
    let url = this._baseUrl + '/api/user/Ping1';


    //    this.http.get(url).pipe(retry(1), catchError(this.errorHandl)).subscribe(response => this.CompletePing(response));


    var obs = this.http.get(url);//.pipe(retry(1), catchError(this.errorHandl)).subscribe(response => this.CompletePing(response));

    obs.subscribe(
      res => console.log('HTTP response', res),
      err => console.log('HTTP Error', err),
      () => console.log('HTTP request completed.')
    );


  }


  // Error handling
  errorHandl(error) {



    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);

  }

  /**
   *
   *
   * TOP LEVEL CALLS
   *
   * 
   * */



  //public TokenValiditiy(): any {
  //  const tokenObservable = new Observable(observer => {
  //    //setTimeout(() => {
  //    //  observer.next(this.tokenValid);
  //    //}, 1000);

  //    //setInterval(() => {
  //    //  observer.next(this.tokenValid);
  //    //}, 1000);

  //    observer.

  //    setInterval(() => {
  //      observer.next(this.tokenValid);
  //    }, 1000);

  //  });

  //  return tokenObservable;
  //}






  private TokenCheck(): Observable<any> {

    // Http Headers
    const httpOptions: { headers; observe; } = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + this.token

      }),
      observe: 'response'
    };

    let url = this._baseUrl + "/Authentication/TokenCheck";

    return this.http.get(url, httpOptions).pipe(retry(1)/*, catchError(this.errorHandl)*/);
  }
  /*
  public RequestMicrosoftToken() {
    let url = "https://login.microsoftonline.com/" + this.microsoftTenantId + "/oauth2/v2.0/token";
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    
    });
    const options = {
      headers,

    };

    const acceptReject: MicrosoftRequest = {
      grant_type: "authorization_code",
      code: this.microsoftCode,
      client_id: this.microsoftClientId,
      client_secret: this.microsoftSecret,
      redirect_url: window.location.origin,
      
    }
   

    //let body =  JSON.stringify(acceptReject);
    let form = 'grant_type=' + acceptReject.grant_type + '&';
    form += 'code=' + acceptReject.code + '&';
    form += 'client_id=' + acceptReject.client_id + '&';
    form += 'client_secret=' + acceptReject.client_secret + '&';
    form += 'redirect_uri=' + acceptReject.redirect_url ;


    const formData = new HttpParams()
    .set('grant_type', 'authorization_code')
    .set('code', this.microsoftCode)
    .set('client_id', this.microsoftClientId)
    .set('client_secret', this.microsoftSecret)
    .set('redirect_uri', window.location.origin);

   // return this.http.post(url, formData, options);
    return this.http.post(url, formData);

  }
  */
  public GetUserRequests(approved: any) {
    // Http Headers
    //const httpOptions: { headers; observe; } = {
    //  headers: new HttpHeaders({
    //    'Authorization': 'bearer ' + this.token

    //  }),
    //  observe: 'response'
    //};

    //let url = "https://irslauthenticationservice.irsltesting.com/api/User/UsersRequestingAccess"

    //if (approved !=null)
    //  url += "/?approved=" + approved.toString();


    //return this.http.get(url, httpOptions).pipe(retry(1), catchError(this.errorHandl));

    var query="";
    if (approved != null)
      query += "approved=" + approved.toString();
    else
      query = null;

    return this.requestObject("UsersRequestingAccess", query);

  }

  public GetOrganizations() {
    return this.requestObject("Organizations", null);
  }

  public RequestMicrosoftToken() {
    const query = "code=" + this.microsoftCode + "&redirectUri=" + window.location.origin;
    return this.requestObject("MicrosoftOath2Token", query);
  }



  public LoginUser(username: string, password: string) {
    var query = "username=" + username + "&password=" + password;
    return this.requestObject("LoginUser", query);
  }



  private requestObject(call: string, query: string) {

    const httpOptions: { headers; observe; } = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + this.token

      }),
      observe: 'response'
    };

    let url = this._baseUrl + "/api/User/" + call

    if (query != null)
      url += "/?" + query;


    return this.http.get(url, httpOptions).pipe(retry(1), catchError(this.errorHandl));

  }





  public RequestUser(user: UserRequest): Observable<any> {

    //Create the object
    const data = JSON.stringify(user);

    return this.transmitObjectNoToken("RequestUserAccess", data);

  }

  public AcceptRejectRequest(acceptReject: UserAcceptReject) {
    acceptReject.reviewer = this.currentUserEmailAddress;
    const data = JSON.stringify(acceptReject);
    return this.transmitObject("user", "AcceptOrRejectUserAccess", data);

  }

  public RevokeAccess(revoke: UserRevoke) {
    revoke.reviewer = this.currentUserEmailAddress;
    const data = JSON.stringify(revoke);
    return this.transmitObject("user", "RevokeUserAccess", data);
  }

  public CreateMicrosoftAdUser(user: UserAd) {
    let url = "https://graph.microsoft.com/v1.0/users";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + this.microsoftToken
    });
    const options = {
      headers,

    };

    let body = JSON.stringify(user);

    return this.http.post(url, body, options);

  }

  public RequestMicrosoftAdUserId(usernameAd: string) {

    let url = "https://graph.microsoft.com/v1.0/users/" + usernameAd;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + this.microsoftToken
    });
    const options = {
      headers,

    };

    return this.http.get(url, options);
  }

  public CreateLocalUserAssociation(user: RegisterUser): Observable<any> {

    let body = JSON.stringify(user);
    return this.transmitObject("authentication", "RegisterAndLinkMicrosoftAD", body);
  }


  /**
   *
   *
   * SUPPORTING FUNCTIONS
   *
   *
   * */



  private transmitObjectNoToken(call: string, body: string): Observable<any> {
    let url = this._baseUrl + '/api/user/' + call;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers,

    };

    return this.http.post(url, body, options);
  }
  private transmitObject(controller: string, call: string, body: string): Observable<any> {

    let url = this._baseUrl + '/'; //'https://irslauthenticationservice.irsltesting.com/';

    //Add the controller
    if (controller == "authentication") {
      url += controller + '/' + call;
    }
    else {
      url += 'api/' + controller + '/' + call;
    }


    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + this.token
    });
    const options = {
      headers,

    };

    return this.http.post(url, body, options);
  }






  private extractTokenValidation(res: any) {
    if (res.status == 200)
      return true;
    else
      return false;
  }

  public CompletePing(response: any) {

    if (response.status == 200)
      return true;
    else
      return false;

  }

  public Error(error) {
    this.router.navigate(['login']);
    //this.router.navigate(['main-request']);
  }





}



type UserAccountRequest = {
  id?: string;
  fName?: string;
  lName?: string;
  email?: string;
  adAccount?: string;
  region?: string;
  approved?: boolean;
}



interface UserRequest {
  Id: string;
  FName: string;
  LName: string;
  Email: string;
  Region: string;
}

type UserAcceptReject = {
  id: string;
  approved: boolean;
  reviewer?: string;
}

type UserRevoke = {
  id: string;
  reviewer?: string;
}

type MicrosoftRequest = {
  grant_type: string;
  code: string;
  client_id: string;
  redirect_url: string;
  client_secret: string;
}
type UserAd = {
  AccountEnabled: boolean;
  DisplayName: string;
  MailNickName: string;
  passwordProfile: passwordProfile;
  UserPrincipalName: string;

}

type passwordProfile = {
  forceChangePasswordNextSignIn: boolean;
  password: string;
}

type RegisterUser = {
  AzureAdId: string;
  AzureAdUsername: string;
  AzureAdPassword: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
  FirstName: string;
  LastName: string;
  PaperPassPassword: string;
}
