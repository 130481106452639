import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-user-entry',
  templateUrl: './user-entry.component.html'
})




export class UserEntryComponent implements OnInit {

  public showForm = true;
  public showProgress = false;
  public showComplete = false;

  ngOnInit() {

  }

  constructor(
    private dataService: DataService,
    private http: HttpClient
  ) { }

  onSubmit(userForm: NgForm) {

    console.log(userForm.value);

    console.log(userForm.valid);

    if (!userForm.valid) {
      return;
    }


    var user: any;
    user = userForm.value;
    this.dataService.RequestUser(user).subscribe(response => this.Complete(response));
   // this.RequestUser(user);
  }

  private RequestUser(user: UserRequest) {

    //Create the object
    var data = JSON.stringify(user);
    this.showForm = false;
    this.showProgress = true;
    this.transmitObject(data);
  }


  private transmitObject(body: string) {
    let url = 'https://irslauthenticationservice.irsltesting.com/api/user/RequestUserAccess';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers
    };

    this.showForm = false;
    this.showProgress = true;

    this.http.post(url, body, options)
      .subscribe(response => this.Complete(response));
  }

  public Complete(response: any) {

    this.showForm = false;
    this.showProgress = false;
    this.showComplete = true;

  }
}



interface UserRequest {
  Id: string;
  FName: string;
  LName: string;
  Email: string;
  Region: string;
}
