import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { generate } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './main-authorize.component.html',
})
export class MainAuthorizeComponent {

  private displayModeEnum = { requests: 0, approved: 1, rejected: 2 };
  private displayMode = this.displayModeEnum.requests;
  private token: string;
  public _userAccountsRequests: UserAccountRequest[];
  public regions: string[];
  public showProgress = false;
  public showGrid = true;
  public showComplete = false;
  public newUser;

  public headers = [
    { title: "Organization", asc: false, desc: false, var: "region", reqDisplay: true },
    { title: "First Name", asc: false, desc: false, var: "fName", reqDisplay: true },
    { title: "Last Name", asc: false, desc: true, var: "lName", reqDisplay: true },
    { title: "Email Address", asc: false, desc: false, var: "email", reqDisplay: true },
    { title: "AD Account", asc: false, desc: false, var: "adAccount", reqDisplay: true },
    { title: "Requested Organization", asc: false, desc: false, var: "requestedOrg", reqDisplay: true },
    { title: "Justification", asc: false, desc: false, var: "justification", reqDisplay: true },
    { title: "Request Date", asc: false, desc: false, var: "requestTimestamp", reqDisplay: true },
    { title: "Reviewer", asc: false, desc: false, var: "reviewer", reqDisplay: false },
    { title: "Reviewed Date", asc: false, desc: false, var: "reviewedTimestamp", reqDisplay: false },
  ]

  constructor(private router: Router, private dataService: DataService, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.dataService.token != null)
      this.dataService.TokenValidity().subscribe(results => this.TokenValidity(results));
    else
      this.router.navigate(['login']);
      //this.router.navigate(['main-request']);



    this.route.queryParams.subscribe(queryParams => this.DetermineAction(queryParams));
  }


  private onCompleteNewUser() {
    this.showProgress = false;
    this.showGrid = true;
    this.showComplete = false;
  }

  private async DetermineAction(params: any) {

    await this.dataService.GetOrganizations().subscribe(res => this.ReceiveRegions(res));

    this.showProgress = true;
    this.showGrid = false;
    this.showComplete = false;


    if (params.approved == "true") {
      this.dataService.GetUserRequests(true).subscribe(res => this.ReceiveUserRequests(res));
      this.displayMode = this.displayModeEnum.approved;
    }
    else if (params.rejected == "true") {
      this.dataService.GetUserRequests(false).subscribe(res => this.ReceiveUserRequests(res));
      this.displayMode = this.displayModeEnum.rejected;
    }
    else {
      this.dataService.GetUserRequests(null).subscribe(res => this.ReceiveUserRequests(res));
      this.displayMode = this.displayModeEnum.requests;
    }

    
  }

  private TokenValidity(result: any) {

    if (result > 0) {
      this.router.navigate(['main-authorize']);
    }
    else if (result < 0) {
      this.router.navigate(['login']);
      //this.router.navigate(['main-request']);
    }

  }

  private ReceiveUserRequests(res: any) {
    this.showGrid = true;
    this.showProgress = false;
    this.showComplete = false;

    this._userAccountsRequests = res.body as UserAccountRequest[];

    this.PopulateRequestedOrganizations();
  }

  private ReceiveRegions(res: any) {

    this.regions = res.body as string[];

    this.PopulateRequestedOrganizations();
  }

  private PopulateRequestedOrganizations() {
    if (this._userAccountsRequests && this.regions) {

      this._userAccountsRequests.forEach((req) => {
        if (!this.regions.includes(req.region)) {
          req.requestedOrg = req.region;
          req.region = '';
        }
      });
    }
  }

  public headerClicked(header: any) {

    if (!header.desc && !header.asc) {
      this.headers.forEach(function (h) {
        h.desc = false;
        h.asc = false;
      });

      header.desc = true;
    }
    else {
      header.desc = !header.desc;
      header.asc = !header.asc;
    }

    this.orderTable(header);
  }

  private orderTable(header: any) {
    if (header.desc) {
      this._userAccountsRequests.sort((a, b) => (a[header.var] < b[header.var]) ? -1 : 1);
    }
    else {
      this._userAccountsRequests.sort((a, b) => (a[header.var] > b[header.var]) ? -1 : 1);
    }
  }

  public onAccept(id: string) {

    if (confirm("Accept this user?")) {
      this.showGrid = false;
      this.showProgress = true;
      const acceptReject: UserAcceptReject = {
        id: id,
        approved: true
      }


      //Send Acceptance
      this.dataService.AcceptRejectRequest(acceptReject).subscribe(response => this.AcceptRejectResponseReturned(response));


      //Create the Microsoft AD Account followed by the local and linking call.
      let selectedUser = this._userAccountsRequests.find(x => x.id == id);

      const passProf: passwordProfile = {
        forceChangePasswordNextSignIn: true,
        password: this.randomPassword()
      }

      let adEmail = selectedUser.fName + '.' + selectedUser.lName + "@" + this.dataService._domain;// "@irsltesting.com"

      const user: UserAd = {
        AccountEnabled: true,
        DisplayName: selectedUser.fName + ' ' + selectedUser.lName,
        MailNickName: selectedUser.fName + selectedUser.lName,
        passwordProfile: passProf,
        UserPrincipalName: adEmail

      }
      this.dataService.CreateMicrosoftAdUser(user).subscribe(response => this.CreateMicrosoftAdUserResponseReturned(response, user, selectedUser));
    }

  }

  private randomPassword() {
    var LOWER_CASE = "bcdfghjklmnpqrsvwxyz";
    const UPPER_CAES = "BCDFGHJKLMNPQRSTVWXYZ";
    const NUMBERS = "123456789";
    const SPECIALS = "!@#$%&*=+";
    var pass = "";
    var temp = "";

    let length = 12;

    for (var x = 0; x < length / 3; x++) {
      var i = Math.floor(Math.random() * LOWER_CASE.length);
      temp += LOWER_CASE.charAt(i);
    }
    for (var x = 0; x < length / 3; x++) {
      var i = Math.floor(Math.random() * UPPER_CAES.length);
      temp += UPPER_CAES.charAt(i);
    }
    for (var x = 0; x < length / 3; x++) {
      var i = Math.floor(Math.random() * NUMBERS.length);
      temp += NUMBERS.charAt(i);
    }
    for (var x = 0; x < 2; x++) {
      var i = Math.floor(Math.random() * SPECIALS.length);
      temp += SPECIALS.charAt(i);
    }




    while (temp.length > 1) {
      var j = Math.floor(Math.random() * temp.length);
      pass += temp[j];
      temp = temp.substring(0, j) + temp.substring(j + 1, temp.length);

    }


    return pass;
  }

  public onReject(id: string) {

    if (confirm("Reject this user?")) {
      this.showGrid = false;
      this.showProgress = true;

      const acceptReject: UserAcceptReject = {
        id: id,
        approved: false
      }


      this.dataService.AcceptRejectRequest(acceptReject).subscribe(response => this.AcceptRejectResponseReturned(response));
    }
  }

  public onRevoke(id: string) {

    if (confirm("Revoke this user?")) {
      this.showGrid = false;
      this.showProgress = true;

      const revoke: UserRevoke = {
        id: id
      }


      this.dataService.RevokeAccess(revoke).subscribe(response => this.AcceptRejectResponseReturned(true));
    }
  }


  private CreateMicrosoftAdUserResponseReturned(response: any, user: UserAd, request: UserAccountRequest) {

    this.dataService.RequestMicrosoftAdUserId(user.UserPrincipalName).subscribe(response => this.RequestMicrosoftAdUserIdResponseReturned(response, user, request));

  }

  private RequestMicrosoftAdUserIdResponseReturned(response: any, user: UserAd, request: UserAccountRequest) {


    let register: RegisterUser = {
        AzureAdId: response.id,
        AzureAdUsername: user.UserPrincipalName,
        AzureAdPassword: "",
        PaperPassPassword: this.randomPassword(),
        Password: user.passwordProfile.password,
        ConfirmPassword: user.passwordProfile.password,
        Email: request.email,
        FirstName: request.fName,
        LastName: request.lName,
        Organization: request.region,
        Apps: ['Er']
    }

    this.dataService.CreateLocalUserAssociation(register).subscribe(res => this.ReceivedCreateLocalUserAssociation(res, register));

  }

  private ReceivedCreateLocalUserAssociation(response: any, registeredUser: RegisterUser) {

    //This is the end of the sequencial calls for creating a user.  Prompt the user an tell them the paper pass pw to forward

    this.showProgress = false;
    this.showGrid = false;
    this.showComplete = true;
    this.newUser = registeredUser;


  }

  private AcceptRejectResponseReturned(response: any) {
    console.log(response);
    this.dataService.GetUserRequests(response).subscribe(res => this.ReceiveUserRequests(res));

  }


}

type UserAccountRequest = {
  id?: string;
  fName?: string;
  lName?: string;
  email?: string;
  adAccount?: string;
  justification?: string;
  region?: string;
  requestedOrg?: string;
  approved?: boolean;
  reviewer: string;
  reviewedTimestamp: any;
}


type UserAcceptReject = {
  id: string;
  approved: boolean;
  reviewer?: string;
}

type UserRevoke = {
  id: string;
  reviewer?: string;
}


type UserAd = {
  AccountEnabled: boolean;
  DisplayName: string;
  MailNickName: string;
  passwordProfile: passwordProfile;
  UserPrincipalName: string;
}

type passwordProfile = {
  forceChangePasswordNextSignIn: boolean;
  password: string;
}

type RegisterUser = {
  AzureAdId: string;
  AzureAdUsername: string;
  AzureAdPassword: string
  Email: string;
  Password: string;
  ConfirmPassword: string;
  FirstName: string;
  LastName: string;
  PaperPassPassword: string;
  Organization: string;
  Apps: string[];
}

