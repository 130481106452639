import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
/** login component*/
export class LoginComponent {
    /** login ctor */
  constructor(
    private router: Router,
    private dataService: DataService,
    private http: HttpClient
  ) { }

  invalidCreds: boolean = false;

  onSubmit(userForm: NgForm) {

    //2025868100

    this.dataService.LoginUser(userForm.value.username, userForm.value.password).subscribe(response => this.Complete(response));
  }

  public Complete(response: any) {
    if (response.body == true) {
      this.router.navigate(['user-entry']);
    }
    else {
      this.invalidCreds = true;
    }
  }
}
