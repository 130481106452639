import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';

//import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {


  token = "asdf";

  _rawToken: string;


  //constructor(private cookieService: CookieService) {

  //}

  constructor(private router: Router, private dataService: DataService, private route: ActivatedRoute) {
  }

  async ngOnInit() {

    this.dataService.initialize().then(a => {
      //STEP 1a - Get the local toke from the cookie

      //  this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiaXN0bGVqbUBudi5kb2UuZ292IiwiREFSRXZlbnQiOiJPY3QxMi5BZG1pbiIsInJvbGVzIjpbIlNpdGUgQWRtaW4iLCJOUkFULVciXSwiUGVybWlzc2lvbiI6IkFkZCBVc2VyIiwic3ViIjoiaXN0bGVqbUBudi5kb2UuZ292IiwianRpIjoiNzc0OWU0MTItNDkyYy00NWNkLWFmNTAtZDE0N2U2ZTMzNTEwIiwiaWF0IjoxNjExNzc1NDY4LCJuYmYiOjE2MTE3NzU0ODIsImV4cCI6MTYxMTg2MTg4MiwiaXNzIjoiZG9lcmVyLnVzIiwiYXVkIjoiQWRkIGFjY2VzcyIsImFwcHMiOiJBcHAgUGFnZSJ9.OPUQKILsbKkaUUWKn9kkqaemq5IYAn8xSiMBHBBjBYE";

      this.token = this.getCookie("RslAppPageToken");


      this.dataService.SetToken(this.token);


      //STEP 1b - Get a the (Microsoft)code from url parameters
      this.dataService.microsoftCode = this.route.snapshot.queryParams.code;

      //STEP 2 - If no token, then redirect to "request" page. Otherwise verify the token
      if (this.dataService.token != null)
        this.dataService.TokenValidity().subscribe(results => this.TokenValidity(results));
      else
        this.router.navigate(['login']);
      //this.router.navigate(['main-request']);

    // this.route.queryParams.subscribe(queryParams => this.DetermineAction(queryParams));
    });
   

  }

  private setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }


  //private getCookie(name: string) {
  //  let ca: Array<string> = document.cookie.split(';');
  //  let caLen: number = ca.length;
  //  let cookieName = `${name}=`;
  //  let c: string;

  //  for (let i: number = 0; i < caLen; i += 1) {
  //    c = ca[i].replace(/^\s+/g, '');
  //    if (c.indexOf(cookieName) == 0) {
  //      return c.substring(cookieName.length, c.length);
  //    }
  //  }
  //  return '';
  //}

  private getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }




  private TokenValidity(result: any) {


    //STEP 3a - If token is verified
    //          * Request a Microsoft Code if one is not present
    //          * Request a Microsoft Token if one is not present
    //          * Route to main-authorize if the above has been met
    //
    // TODO: It would be cool to make sure the 2 tokens have not expired... more so on the MS Token

    if (result > 0) {

      if (this.dataService.microsoftCode == null) {
        const absouluteUri = window.location.origin.replace("//", "%2F%2F");
        window.location.href = "https://login.microsoftonline.com/irsltesting.com/oauth2/v2.0/authorize?client_id=" + this.dataService.microsoftClientId + "&response_type=code&redirect_uri=" + absouluteUri + "&response_mode=query&scope=User.ReadBasic.All%20User.Read%20User.ReadWrite%20User.Read.All%20User.ReadWrite.All%20Directory.Read.All%20Directory.ReadWrite.All%20Directory.AccessAsUser.All%20UserAuthenticationMethod.ReadWrite.All&state=12345";
      }
      else if (this.dataService.microsoftToken == null) {
        this.dataService.RequestMicrosoftToken().subscribe(result => this.ReceivedMicrosoftResponse(result));
      }
      else {
        this.router.navigate(['main-authorize']);
      }

    }
    else if (result < 0) {

      //this.router.navigate(['main-request']);
      this.router.navigate(['login']);
    }

  }

  private ReceivedMicrosoftResponse(result: any) {


    /**
     * YOU LEFT OFF HERE!
     *
     * Check to make sure status 200 and a microsoftToken is returned  (right now you are getting 400 bad request).
     *
     * Store the MS Token and then this.router.navigate(['main-authorize']);
    */

    let response = result.body as MicrosoftTokenResponse;

    this.dataService.microsoftToken = response.accessToken;
    this.router.navigate(['main-authorize']);


  }


  private HandleError(error: any) {
    //this.router.navigate(['main-request']);
    this.router.navigate(['login']);
  }

  private TokenResult(result: any) {

    if (result.status == 200) {
      this.router.navigate(['main-authorize']);
    }

  }





  public setTokenString(token: string) {
    this._rawToken = token;
  }


}



type MicrosoftTokenResponse = {
  accessToken?: string;

}

